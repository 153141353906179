/**
 * 路由配置
 */
import NProgress from 'nprogress';
import { createRouter, createWebHistory } from 'vue-router';
import { PROJECT_NAME, WHITE_LIST, REDIRECT_PATH, SSO_FLAG, SSO_CLIENT_ID, SSO_CLIENT_ID_PROD } from '@/config/setting';
import { useUserStore } from '@/store/modules/user';
import { getToken } from '@/utils/token-util';
import { routes, getMenuRoutes } from './routes';
import { SsoUtil } from '@/utils/sso-util';
import { useSystemStore } from '@/store/modules/system';
import { SysConfigApi } from '@/api/system/basedata/SysConfigApi';
import { LoginApi } from '@/api/login/LoginApi';

NProgress.configure({
  speed: 200,
  minimum: 0.02,
  trickleSpeed: 200,
  showSpinner: false
});

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  }
});

/**
 * 路由守卫
 */
router.beforeEach(async (to, from) => {
  // 网站顶部小进度条开启
  if (!from.path.includes(REDIRECT_PATH)) {
    NProgress.start();
  }

  // 更新网站标题
  updateTitle(to);

  // 当前用户没有登录，没有token
  if (!getToken()) {
    // 当前路径不存在白名单内并且非静态资源请求地址
    if (!WHITE_LIST.includes(to.path) && !to.path.includes('/assets')) {
      // 当前路径不在白名单，如果开启了sso，并且未携带有errorCode（单点返回来的），跳转到单点detection
      if (SSO_FLAG && !to.query.errorCode && !to.query.token) {
        SsoUtil.redirectDetection();
      } else {
        // 没开启sso，直接跳转到登录界面
        return {
          path: '/login',
          query: to.path === '/' ? {} : { from: to.path }
        };
      }
    }
  }

  // 可以获取到token，代表用户已经登录了
  else {
    // 开启了 SSO，并且 URL 中存在 ssoClientId，即正在跳转登录
    if (SSO_FLAG && to.query?.clientId) {
      const ssoClientId = to.query?.clientId;
      const ssoCallback = to.query?.ssoCallback;
      // 非门户自身登录，即为子系统跳转登录
      if (ssoClientId !== SSO_CLIENT_ID_PROD) {
        const res = await LoginApi.getLoginCode();
        SsoUtil.activateByLoginCode(ssoClientId ?? SSO_CLIENT_ID, ssoCallback ?? '', res?.data?.ssoLoginCode);
      }
      return true;
    }

    // 未开启 SSO

    // 校验系统是否初始化过，如果没有初始化过，则进入初始化界面初始化一些后台需要的参数
    const systemStore = useSystemStore();

    if (systemStore.alreadyInitConfig === null || !systemStore.alreadyInitConfig) {
      // 获取是否系统初始化过配置
      let alreadyInit = await SysConfigApi.getInitConfigFlag();

      // 更新store中存储的标识
      systemStore.updateInitFlag(alreadyInit);

      // 如果没有初始化系统配置，并且当前跳转的界面不是init界面，则跳转到init界面
      if (!alreadyInit && to.path !== '/init') {
        return { path: '/init' };
      }
    }

    // 注册动态路由
    const userStore = useUserStore();
    if (!userStore.menus) {
      const { menus, homePath } = await userStore.fetchUserInfo();
      if (menus) {
        router.addRoute(getMenuRoutes(menus, homePath));
        return { ...to, replace: true };
      }
    }
  }
});

router.afterEach(to => {
  if (!to.path.includes(REDIRECT_PATH) && NProgress.isStarted()) {
    setTimeout(() => {
      NProgress.done(true);
    }, 200);
  }
});

/**
 * 更新浏览器标题
 * @param route 路由信息
 */
function updateTitle(route) {
  if (route && !route.path?.includes(REDIRECT_PATH)) {
    const names = [];
    if (route.meta?.title) {
      names.push(route.meta.title);
    }
    if (PROJECT_NAME) {
      names.push(PROJECT_NAME);
    }
    document.title = names.join(' - ');
  }
}

export default router;
