/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * 用户名校验
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  return /^[a-zA-Z0-9_]{4,12}$/.test(str);
}

/**
 * 昵称校验
 * @param {string} str
 * @returns {Boolean}
 */
export function validNickName(str) {
  return /^[\S]{2,20}$/.test(str);
}

/**
 * 密码校验
 * @param {string} str
 * @returns {boolean}
 */
export function validPassword(str) {
  return /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()\[\]_+`\-="':;<>,./?{}|]).{8,20}$/.test(str);
}

/**
 * 手机号校验
 * @param {string} str
 * @returns {boolean}
 */
export function validPhone(str) {
  return /^1\d{10}$/.test(str);
}

/**
 * 邮箱地址校验
 * @param {string} str
 * @returns {boolean}
 */
export function validEmail(str) {
  return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(str);
}

/**
 * 验证码校验
 * @param {string} str
 * @returns {boolean}
 */
export function validVerificationCode(str) {
  return /^\d{6}$/.test(str);
}
