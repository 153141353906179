<!-- 修改密码弹窗 -->
<template>
  <ele-modal
    :width="420"
    title="修改密码"
    :visible="visible"
    :confirm-loading="loading"
    :body-style="{ paddingBottom: '16px' }"
    @update:visible="updateVisible"
    @cancel="onCancel"
    @ok="onOk"
  >
    <a-form :label-col="{ sm: { span: 6 } }" :wrapper-col="{ sm: { span: 18 } }">
      <a-form-item label="旧密码" v-bind="validateInfos.password">
        <a-input-password v-model:value="form.password" placeholder="请输入旧密码" />
      </a-form-item>
      <a-form-item label="新密码" v-bind="validateInfos.newPassword">
        <a-input-password v-model:value="form.newPassword" placeholder="请输入新密码" />
      </a-form-item>
      <a-form-item label="确认密码" v-bind="validateInfos.password2">
        <a-input-password v-model:value="form.password2" placeholder="请再次输入新密码" />
      </a-form-item>
    </a-form>
  </ele-modal>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { Form, message } from 'ant-design-vue';
import { updatePassword } from '@/api/layout';
import { validPassword } from '@/utils/validate';
const useForm = Form.useForm;

const emit = defineEmits(['update:visible']);

defineProps({
  visible: Boolean
});

// 提交loading
const loading = ref(false);

// 表单数据
const form = reactive({
  password: '',
  newPassword: '',
  password2: ''
});

// 表单验证规则
const rules = reactive({
  password: [
    {
      required: true,
      type: 'string',
      message: '请输入旧密码',
      trigger: 'blur'
    }
  ],
  newPassword: [
    { required: true, message: '请输入新密码', type: 'string', trigger: 'blur' },
    {
      required: true,
      type: 'string',
      trigger: 'blur',
      validator: (rule, value) => {
        if (!validPassword(value)) {
          return Promise.reject('密码必须包含字母、数字和特殊符号，长度为8-20位');
        } else {
          return Promise.resolve();
        }
      }
    }
  ],
  password2: [
    { required: true, message: '请再次输入密码以确认', type: 'string', trigger: 'blur' },
    {
      type: 'string',
      trigger: 'blur',
      validator: async (rule, value) => {
        const password = form.password2;
        if (value && password !== value) {
          return Promise.reject('两次输入的密码不一致');
        } else {
          return Promise.resolve();
        }
      }
    }
  ]
});

const { resetFields, validate, validateInfos } = useForm(form, rules);

/* 修改visible */
const updateVisible = value => {
  emit('update:visible', value);
};

/* 保存修改 */
const onOk = () => {
  validate()
    .then(() => {
      loading.value = true;
      updatePassword(form)
        .then(msg => {
          loading.value = false;
          message.success(msg);
          updateVisible(false);
        })
        .catch(e => {
          loading.value = false;
          message.error(e.message);
        });
    })
    .catch(() => {});
};

/* 关闭回调 */
const onCancel = () => {
  resetFields();
  loading.value = false;
};
</script>
