import Request from '@/utils/request-util';

/**
 * @Description 顶部工具栏api
 *
 * @author Sudocat
 * @date 2022/08/02 12:30:46
 */
export class HeaderToolsApi {
  /**
   * @Description 手动身份绑定
   *
   * @author Sudocat
   * @date 2022/08/02 12:41:46
   */
  static bindIdentities(params) {
    return Request.get('/sysUser/bindIdentities', params);
  }
}
